import {StyleExtractor} from "@kubric/utils";
import React from "react";
import ChevronDown from "react-feather/dist/icons/chevron-down";

import * as styles from "./styles.module.scss";

const FaqSection = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {heading, faqs} = data;
  const [faq, setFaq] = React.useState();
  return (
    <div className={styler.get("sectionWrap", "full-bleed")}>
      <h2 className={styler.get("heading")}>{heading}</h2>

      <div className={styler.get("faqs")}>
        {faqs.map(({answer, question}, i) => (
          <div
            className={styler.get(["faq", i === faq && "active"])}
            onClick={() => setFaq(faq === i ? undefined : i)}
          >
            <div className={styler.get("question")}>
              <span>{question}</span>
              <ChevronDown />
            </div>
            <span className={styler.get("answer")}>{answer}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqSection;

// extracted by mini-css-extract-plugin
export var alternateBg = "styles-module--alternateBg--3-4BB";
export var bottom = "styles-module--bottom--1EWWo";
export var card = "styles-module--card--3uDfB";
export var cardContent = "styles-module--cardContent--2lGdx";
export var cardHeader = "styles-module--cardHeader--39N0X";
export var cards = "styles-module--cards--2YXBs";
export var description = "styles-module--description--3ZglC";
export var designElement = "styles-module--designElement--162Yt";
export var heading = "styles-module--heading--3Ncal";
export var imageContainer = "styles-module--imageContainer--1rl5N";
export var sectionWrap = "styles-module--sectionWrap--19ReR";
export var top = "styles-module--top--1Hnbk";
import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import * as styles from "./styles.module.scss";

const PurpleSection = ({data, isVideoSectionShown = false}) => {
  const styler = new StyleExtractor(styles);
  const {heading, description, features} = data;
  return (
    <div
      className={styler.get(
        ["sectionWrap", isVideoSectionShown && "alternateBg"],
        "full-bleed"
      )}
    >
      <h2 className={styler.get("heading")}>{heading}</h2>
      <div className={styler.get("description")}>{description}</div>
      <div className={styler.get("cards")}>
        {features.map(({_key, content, title, image}) => (
          <div className={styler.get("card")} key={_key}>
            <div className={styler.get("cardHeader")}>
              <div className={styler.get("imageContainer")}>
                <GatsbyImage
                  image={image?.image?.asset?.gatsbyImageData}
                  alt={image?.alt}
                />
              </div>
              <span>{title}</span>
            </div>
            <div className={styler.get("cardContent")}>{content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PurpleSection;

import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import MMCta from "../../../commons/MMCTA";
import * as styles from "./styles.module.scss";

const Hero = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {description, heading, image, mImage} = data;
  return (
    <div className={styler.get("heroWrap", "full-bleed layouted flex")}>
      <div className={styler.get("content", "flex")}>
        <div className={styler.get("left", "flex flex-col")}>
          <h1 className={styler.get("heading")}>{heading}</h1>
          <div className={styler.get("description")}>{description}</div>
          <div className={styler.get("heroCta")}>
            <MMCta
              cta='Get Started'
              href='https://www.shopify.com/admin/oauth/authorize?client_id=70a074b87b07f9468ceafd018c360396&scope=write_products%2Cwrite_script_tags%2Cwrite_themes%2Cread_discounts%2Cread_orders%2Cread_inventory%2Cread_price_rules&redirect_uri=https%3A%2F%2Fapp.getmodemagic.com%2Finstall%2Fcallback'
              id='install-mm-app'
              v3
              isHeroCTA
            />
          </div>
        </div>
        <div className={styler.get("right", "flex flex-col")}>
          <div className={styler.get("heroImageWrapper")}>
            <div className={styler.get("heroImage")}>
              <GatsbyImage
                image={
                  // in case different mobile image is used later
                  // isMobile
                  // ? mImage?.image?.asset?.gatsbyImageData ||
                  image?.image?.asset?.gatsbyImageData
                  // : image?.image?.asset?.gatsbyImageData
                }
                alt={image?.alt}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

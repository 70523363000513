import {graphql} from "gatsby";
import React from "react";

import LastSection from "../../components/AboveFooterSection";
import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import FaqSection from "../../components/pages/featureDetails/FaqSection";
import Hero from "../../components/pages/featureDetails/Hero";
import PurpleSection from "../../components/pages/featureDetails/PurpleSection";
import Section4 from "../../components/pages/featureDetails/Section4";
import VideoSection from "../../components/pages/featureDetails/VideoSection";
import MMReviews from "../../components/pages/modemagic/Reviews";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import "../../pages/styles.scss";

const FeatureDetailsPage = ({data, pageContext: {data: cmsData}}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];
  const {reviews} = data.allSanityModemagicPage.nodes[0];
  const isMobile = useMediaQuery("(max-width: 900px)");
  const {hero, video, purple, section4, faq, bottomCta} = cmsData;

  return (
    <Layout seoData={seoData} headerProps={{showMMCta: true}}>
      <StructuredData
        type={StructuredDataType.PRODUCT}
        data={{key: "features"}}
      />
      <Buffer buffer={81} mobileBuffer={30} />
      <Hero data={hero} />
      <VideoSection data={video} />
      <PurpleSection isVideoSectionShown={video?.show} data={purple} />
      <Section4 data={section4} />
      <MMReviews data={reviews} isMobile={isMobile} />
      <FaqSection data={faq} />
      <LastSection data={bottomCta} openAppListingPage hideBackgroundTicket />
    </Layout>
  );
};

export const query = graphql`
  query ($tag: String) {
    allSanitySeoMeta(filter: {page: {eq: $tag}}) {
      nodes {
        title
        canUrl
        page
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityModemagicPage(filter: {page: {eq: "mm"}}) {
      nodes {
        reviews {
          _rawHeading
          _rawMobileHeading
          fReview {
            person
            text
            designation
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
          }
          sReview {
            person
            text
            designation
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
          }
        }
      }
    }
  }
`;

export default FeatureDetailsPage;

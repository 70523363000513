// extracted by mini-css-extract-plugin
export var active = "styles-module--active--1GeAQ";
export var content = "styles-module--content--2FEPS";
export var description = "styles-module--description--2Zw4T";
export var heading = "styles-module--heading--2pYBo";
export var image = "styles-module--image--UIHLz";
export var imageContainer = "styles-module--imageContainer--21x4w";
export var info = "styles-module--info--yeHzc";
export var infoContainer = "styles-module--infoContainer--d8zL9";
export var infoContent = "styles-module--infoContent--RX7hn";
export var sectionWrap = "styles-module--sectionWrap--2QxTq";
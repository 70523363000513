import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import ChevronDown from "react-feather/dist/icons/chevron-down";

import * as styles from "./styles.module.scss";

const Section4 = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {description, heading, features, ctaText, ctaUrl} = data;
  const [selected, setSelected] = React.useState(0);

  return (
    <div className={styler.get("sectionWrap", "full-bleed")}>
      <h2 className={styler.get("heading")}>{heading}</h2>
      <div className={styler.get("description")}>{description}</div>
      <div className={styler.get("content")}>
        <div className={styler.get("infoContainer")}>
          <div className={styler.get("infoContent")}>
            {features.map(({content, title, _key}, i) => (
              <div
                className={styler.get(["info", i === selected && "active"])}
                onClick={() => setSelected(i)}
                key={_key}
              >
                <div className={styler.get("heading")}>
                  <p>{title}</p>
                  <ChevronDown />
                </div>
                <p className={styler.get("description")}>{content}</p>
              </div>
            ))}
          </div>
          <OutboundLink href={ctaUrl} target='_blank' rel='noopener noreferrer'>
            {ctaText}
          </OutboundLink>
        </div>
        <div className={styler.get("imageContainer")}>
          {features.map(({image}, i) => (
            <GatsbyImage
              image={image?.image?.asset?.gatsbyImageData}
              alt={image?.alt}
              className={styler.get(["image", selected === i && "active"])}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section4;

// extracted by mini-css-extract-plugin
export var active = "styles-module--active--Ppdcb";
export var answer = "styles-module--answer--34qUl";
export var bottom = "styles-module--bottom--fPWsh";
export var designElement = "styles-module--designElement--2RI8X";
export var faq = "styles-module--faq--3t3vC";
export var faqs = "styles-module--faqs--2NvnL";
export var heading = "styles-module--heading--1-WVX";
export var question = "styles-module--question--22BxG";
export var sectionWrap = "styles-module--sectionWrap--27Fok";
export var top = "styles-module--top--2p2c3";
import {StyleExtractor} from "@kubric/utils";
import React from "react";

import HomeDesignElement from "../../../../assets/HomeDesignElement.svg";
import * as styles from "./styles.module.scss";

const VideoSection = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {description, heading, video, show = false} = data;

  if (!show) {
    return null;
  }

  return (
    <div className={styler.get("sectionWrap", "full-bleed")}>
      <HomeDesignElement className={styler.get(["designElement", "top"])} />

      <h2 className={styler.get("heading")}>{heading}</h2>
      <div className={styler.get("description")}>{description}</div>
      <video loop muted autoPlay playsInline>
        <source src={video?.asset?.url} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoSection;
